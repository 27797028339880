
import { AiFillDashboard } from "react-icons/ai";
import { GoGraph } from "react-icons/go";
import { BsCircle } from "react-icons/bs";
import { BiCalendarPlus, BiBarChartAlt } from "react-icons/bi";
import { BiCalendarCheck } from "react-icons/bi";
import { AiTwotoneFilePdf } from "react-icons/ai";
import { FaBuilding } from "react-icons/fa";
import { FaUserMd , FaHospital } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";
import { FaBook } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";

export const localAdminLinks = [
    {
        heading: false,
        title:"Dashboard",
        icon: <AiFillDashboard />,
        to : '/dashboard',
        nested: false,
        level: 1,
    },
    {
        heading: false,
        title:"Statistics",
        icon: <BiBarChartAlt />,
        to : '/statistics',
        nested: true,
        level: 1,
        links: [
            {
                title:"Medical education activities",
                to:'/statistics/medical-education-activities',
                icon:<BiBarChartAlt />,
                nested:false,
                level: 2,
            },

            {
                title:"Focus on Perceval",
                to:'/',
                icon:<BiBarChartAlt />,
                nested:true,
                level: 2,
                links:[
                    {
                        title:"Perceval growth",
                        to:'/',
                        icon:<BsCircle />,
                        level: 3
                    },
                    {
                        title:"Efficacy",
                        to:'/',
                        icon:<BsCircle />,
                        level: 3
                    },
                    {
                        title:"Efficiency",
                        to:'/',
                        icon:<BsCircle />,
                        level: 3
                    },
                ]
            },
            {
                title:"Focus on HAART",
                to:'/',
                icon:<BiBarChartAlt />,
                nested:true,
                level: 2,
                links:[
                    {
                        title:"HAART growth",
                        to:'/statistics/haart-growth',
                        icon:<BsCircle />,
                        level: 3
                    },
                    {
                        title:"Efficacy",
                        to:'/statistics/haart-efficacy',
                        icon:<BsCircle />,
                        level: 3
                    },
                    {
                        title:"Efficiency",
                        to:'/statistics/haart-efficiency',
                        icon:<BsCircle />,
                        level: 3
                    },
                ]
            },
            {
                title:"Focus on Speaking Events",
                to:'/',
                icon:<BiBarChartAlt />,
                nested:true,
                level: 2,
                links:[
                    {
                        title:"Growth",
                        to:'/',
                        icon:<BsCircle />,
                    },
                    {
                        title:"Efficacy",
                        to:'/',
                        icon:<BsCircle />,
                    },
                ]
            },
        ]
    },
    {
        heading: true,
        title:"Activities",
        level: 1,
    },
    /* Modality consultation */
    // {
    //     heading: false,
    //     title:"New activity",
    //     icon: <BiCalendarPlus />,
    //     to : '/new-activity',
    //     nested: false,
    //     level: 1,
    // },
    // {
    //     heading: false,
    //     title:"New MICS Courses",
    //     icon: <BiCalendarPlus />,
    //     to : '/mics-courses',
    //     nested: false,
    //     level: 1,
    // },
    /* Modality consultation */
    // {
    //     heading: false,
    //     title:"Import old activities",
    //     icon: <BiCalendarPlus />,
    //     to : '/old-activities',
    //     nested: false,
    //     level: 1,
    // },
    {
        heading: false,
        title:"All single activities",
        icon: <BiCalendarCheck />,
        to : '/all-activities',
        nested: true,
        level: 1,
        links: [
            {
                title:"All Activities",
                to:'/activitylistings/all',
                icon: <BiCalendarCheck />,
                nested:false,
                level: 2,
            }, 
            {
                title:"MICS Courses activities",
                to:'/activitylistings/mics-course',
                icon: <BiCalendarCheck />,
                nested:false,
                level: 2,
            },  
            {
                title:"Proctorship activities",
                to:'/activitylistings/proctorship',
                icon: <BiCalendarCheck />,
                nested:false,
                level: 2,
            },  
            {
                title:"Preceptorship activities",
                to:'/activitylistings/preceptorship',
                icon: <BiCalendarCheck />,
                nested:false,
                level: 2,
            },  
            {
                title:"Master Proctorship activities",
                to:'/activitylistings/master-proctorship',
                icon: <BiCalendarCheck />,
                nested:false,
                level: 2,
            },  
            {
                title:"Speaking events",
                to:'/activitylistings/speaking-events',
                icon: <BiCalendarCheck />,
                nested:false,
                level: 2,
            },  
        ]
    },
    // {
    //     heading: false,
    //     title:"All single activities",
    //     icon: <BiCalendarCheck />,
    //     to : '/all-activities',
    //     nested: false,
    //     level: 1,
    // },
    {
        heading: true,
        title:"MANAGEMENT",
        level: 1,
    },
    {
        heading: false,
        title:"Invoice",
        icon: <AiTwotoneFilePdf />,
        to : '/invoice',
        nested: false,
        level: 1,
    },
    // {
    //     heading: false,
    //     title:"Perceval center certification",
    //     icon: <FaBuilding />,
    //     to : '/perceval-center',
    //     nested: false,
    //     level: 1,
    // },
    // {
    //     heading: false,
    //     title:"Old activities for statistics",
    //     icon: <GoGraph />,
    //     to : '/',
    //     nested: false,
    //     level: 1,
    // },
    // {
    //     heading: false,
    //     title:"Not tracked activities",
    //     icon: <GoGraph />,
    //     to : '/',
    //     nested: false,
    //     level: 1,
    // },
    {
        heading: true,
        title:"INFO",
        level: 1,
    },
    {
        heading: false,
        title:"Consultants",
        icon: <FaUserMd />,
        to : '/consultants',
        nested: false,
        level: 1,
    },
    {
        heading: false,
        title:"Training Hospitals",
        icon: <FaHospital/>,
        to : '/coe',
        nested: false,
        level: 1,
    },
    {
        heading: false,
        title:"Hospitals",
        icon: <FaBuilding />,
        to : '/hospitals',
        nested: false,
        level: 1,
    },
    {
        heading: false,
        title:"Blank form download",
        icon: <FaDownload />,
        to : '/forms-download',
        nested: false,
        level: 1,
    },
    {
        heading: false,
        title:"CORCYM courses",
        icon: <FaBook />,
        to : '/',
        nested: false,
        level: 1,
    },
    {
        heading: true,
        title:"SUPPORT",
        level: 1,
    },
    {
        heading: false,
        title:"Ask for support",
        icon: <FaEnvelope />,
        to : '/support',
        nested: false,
        level: 1,
    },
]
