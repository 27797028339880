import React, {Suspense} from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import {FullScreenLoading} from "../components/Loading"
import { SALES_MANAGER, UNC_ZONES } from '../constants';
import { useSelector } from 'react-redux';

//pages
const AddHospital= React.lazy(() => import("../pages/Info/Hospitals/NewHospital"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const IdleDetection = React.lazy(() => import("../components/IdleDetection"));
const NewActivity = React.lazy(() => import("../pages/NewActivity"));
const MICSCourses = React.lazy(() => import("../pages/MicsCourse"));
const OldActivities = React.lazy(() => import("../pages/OldActivities"));
const AllActivities = React.lazy(() => import("../pages/AllSingleActivities"));
// written by Zain: according to the requirement: now Admin can see the follwoing both pages
// 1) Invoice
// 2) Percevel Center Certification
const PercevelCenterCertification = React.lazy(()=>import("../pages/PercevalCenterCertification"));
const Invoice = React.lazy(() => import("../pages/ActivityListings/Invoice"));
// written by Zain:according to the requirement: now Admin can see the above both pages
// 1) Invoice
// 2) Percevel Center Certification

const UserProfile= React.lazy(() => import("../pages/UserProfile/UserProfile"));

// const COE= React.lazy(() => import("../pages/Info/COE/Coe"));
const COE= React.lazy(() => import("../pages/Info/COE/coe"));

const Hospital= React.lazy(() => import("../pages/Info/Hospitals/Hospitals"));

const Consultant = React.lazy(() => import("../pages/Info/Consultants/Consultants"));

const SupportListing = React.lazy(() => import("../pages/SupportListing"));
const SupportDetail = React.lazy(() => import("../pages/SupportDetail"));


// activities detail
const ProctorshipActivity = React.lazy(() => import("../pages/Activities/Proctorship"));
const MasterProctorshipActivity = React.lazy(() => import("../pages/Activities/MasterProctorship"));
const PrceptorshipActivity = React.lazy(() => import("../pages/Activities/Preceptorship"));
const SpeakingEventActivity = React.lazy(() => import("../pages/Activities/SpeakingEvent"));

// activities detail

// activities listing
const ProctorshipListing = React.lazy(() => import("../pages/ActivityListings/ProctorshipListing/ProctorshipListing"));
const PreceptorshipListing = React.lazy(() => import("../pages/ActivityListings/PreceptorshipListings/PreceptorshipListing"));
const MasterProctorshipListing = React.lazy(() => import("../pages/ActivityListings/MasterProctorshipListing/MasterProctorshipListing"));
const SpeakingEventListing = React.lazy(() => import("../pages/ActivityListings/SpeakingEventListing/SpeakingEventListing"));
const MICSCourseListing = React.lazy(() => import("../pages/ActivityListings/MicsCourseListing"));
const AllActivitiesListing = React.lazy(() => import("../pages/ActivityListings/AllListing"));
// activities listing

// MICS course activity
const MICSCourseActivity = React.lazy(() => import("../pages/MicsCourseActivity"));
// MICS course activity

// Blank forms download
const BlankForms = React.lazy(() => import("../pages/Info/BlankForms/BlankForm"));
// Blank forms download

// statistics
const MedicalEducationActivities = React.lazy(() => import("../pages/Statistics/MedicalEducationActivities"));
// statistics
const NewRealse = React.lazy(() => import("../pages/NewRealse"));



function SalesManagerRoutes() {
  const location = useLocation()
  const user = useSelector(state => state.user);

    return (
        <Suspense fallback={<FullScreenLoading/>}>
          <IdleDetection/>
          <Switch>

          <Route path='/dashboard' exact>
            <Dashboard key={location.key}/>
          </Route>
          <Route path='/new-release' exact>
            <NewRealse key={location.key}/>
          </Route>
          {/* <Route path='/statistics/medical-education-activities' exact>
            <MedicalEducationActivities key={location.key}/>
          </Route> */}

          {/* Activities */}
          {/* Modality consultation  */}
          {/* <Route path='/new-activity' exact>
            <NewActivity key={location.key}/>
          </Route>
          <Route path='/mics-courses' exact>
            <MICSCourses key={location.key}/>
          </Route> */}
          {/* Modality consultation  */}

          {/* <Route path='/old-activities' exact>
            <OldActivities key={location.key}/>
          </Route> */}
          <Route path='/all-activities' exact>
            <AllActivities key={location.key} />
          </Route>

          {/* Management */}
          <Route path="/invoice" exact>
            <Invoice key={location.key}/>
          </Route>
          {/* <Route path='/perceval-center' exact>
            <PercevelCenterCertification/>
          </Route> */}
          {/* Info */}

          {/* Users section below*/}

          <Route path='/profile'  exact>
            <UserProfile key={location.key}/>
          </Route>
          {/* Users section above*/}

          {/* COE section below*/}
          <Route path='/COE' exact>
            <COE key={location.key}/>
          </Route>
          
        
          {/* COE section above*/}

          {/* Hospital section below*/}

          <Route path='/hospitals' exact>
            <Hospital key={location.key}/>
          </Route>
          {/* Modality consultation  */}
          {/* <Route path='/new-hospital' exact>
            <AddHospital key={location.key} is_zone={true}/>
          </Route> */}
          {/* Modality consultation  */}
          {/* Hospital section above*/}

          {/* Consultants section below*/}
          {
            !(UNC_ZONES.includes(user.zone)&&user.role===SALES_MANAGER)&&
            <Route path='/consultants' exact>
              <Consultant key={location.key}/>
            </Route>
          }
          
          {/* Consultants section above*/}

          {/* Blank form page route below */}

          <Route path='/forms-download'  exact>
            <BlankForms key={location.key}/>
          </Route>

          {/* Blank form page route above */}

          {/* Management */}


          {/* ACITIVITIES */}
          <Route path="/activities/proctorship/:id" exact>
            <ProctorshipActivity key={location.key}/>
          </Route>
          <Route path="/activities/masterproctorship/:id" exact>
            <MasterProctorshipActivity key={location.key}/>
          </Route>
          <Route path="/activities/preceptorship/:id" exact>
            <PrceptorshipActivity key={location.key}/>
          </Route>
          <Route path="/activities/speaking-event/:id" exact>
            <SpeakingEventActivity key={location.key}/>
          </Route>

          

          {/* Activity Listings */}
          <Route path="/activitylistings/mics-course" exact>
            <MICSCourseListing key={location.key}/>
          </Route>
          <Route path="/activitylistings/proctorship" exact>
            <ProctorshipListing key={location.key}/>
          </Route>
          <Route path="/activitylistings/master-proctorship" exact>
            <MasterProctorshipListing key={location.key}/>
          </Route>
          <Route path="/activitylistings/preceptorship" exact>
            <PreceptorshipListing key={location.key}/>
          </Route>
          <Route path="/activitylistings/speaking-events" exact>
            <SpeakingEventListing key={location.key}/>
          </Route>
          <Route path="/activitylistings/all" exact>
            <AllActivitiesListing key={location.key}/>
          </Route>


          {/* Activity Listings */}

          {/* ACITIVITIES */}

          {/* MICS COURSE ACTIIVTY */}
          <Route path="/activities/mics-course/:id" exact>
            <MICSCourseActivity key={location.key}/>
          </Route>
          {/* MICS COURSE ACTIIVTY */}

          {/* SUPPORT */}
          <Route exact path="/support">
            <SupportListing key={location.key}/>  
          </Route>
          <Route exact path="/support/:id">
            <SupportDetail key={location.key}/>  
          </Route>
          {/* SUPPORT */}

          
          <Redirect from="/" to="/dashboard"/>
          
          </Switch>

          {/* <Footer /> */}
        </Suspense>
    )
}

export default SalesManagerRoutes
