import React from 'react';
import { NavLink } from 'react-router-dom';
import {UNC_ZONES,SALES_MANAGER} from '../../constants'
import { useSelector } from 'react-redux';

// local components
import SecondLevelLink from "./SecondLevelLink";
// local components

const Sidebar = ({hideSidebar, visible, links, isFeedback}) => {
    const user = useSelector(state => state.user);
    
    const closeSidebar = () => {
        hideSidebar(false);
    }

    return (
        <>
        {
            !isFeedback?
            <>
        {/* overlay */}
            {visible && 
                <div onClick={() => hideSidebar(false)} className={`block lg:hidden bg-black bg-opacity-25 fixed h-full w-full top-0 left-0 z-9999`}>

                </div>   
            }
        {/* overlay */}
        <div className={`transition transform ${!visible ? "-translate-x-full" : "-translate-x-0"} lg:translate-x-0 h-screen fixed left-0 top-10 bottom-0 w-64 lg:w-250 2xl:w-300 bg-gray-bg-dark overflow-y-auto z-10000`}>
            
            {/* logo */}
            <NavLink to="/">
                <img src = "/images/cspro-logo.png" className="w-full bg-white"/>
            </NavLink> 
            {/* logo */}

            {links.map(link => (
                link.nested === false && link.level === 1 ? 
                    <>
                        {/* first level links */}
                        {
                            (link.to==='/consultants'&&user.role===SALES_MANAGER&&UNC_ZONES.includes(user.zone))?
                            ""
                            :
                            <div className="relative">
                            {/* link */}
                            <div className="sidebar-link-height w-12 m-0 w-full">
                                <NavLink to={link.to} onClick={closeSidebar} className="hover:text-gray-textdark border-l-4 hover:border-blue-primary cursor-pointer pl-3 pr-3 origin-bottom m-0 no-underline text-gray-textdark text-sm sidebar-link-height flex items-center justify-between">
                                <span className="text-sm flex items-center">
                                    <span className="text-base mr-2">
                                        {link.icon}
                                    </span>
                                    {link.title}
                                </span>
                                </NavLink>
                            </div>
                            {/* link */}
                            </div>
                        }
                        {/* first level links */}
                    </>


                : link.nested === true && link.level === 1 ?
                <>
                    <SecondLevelLink closeSidebar={closeSidebar} link={link}/>
                </>
                :link.heading ? 
                <>
                    {/* link */}
                    <div className="sidebar-link-height w-12 m-0 w-full bg-red-primary">
                        <p to="/" className="pl-5 pr-3 origin-bottom m-0 no-underline text-white text-xs sidebar-link-height flex items-center justify-between">
                        <span className="uppercase text-xs flex items-center">
                            {link.title}
                        </span>
                        </p>
                    </div>
                    {/* link */}
                </>

                :
                null
                
            ))}
        </div>   
        </>
        :
        null

        }
        </>
    )
}

export default Sidebar
