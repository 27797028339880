import React, { useContext } from 'react'
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeSharp } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { BasePopupContext } from '../../BasePopup';
import { FaInfo } from "react-icons/fa";
import { IoInformationCircleSharp } from "react-icons/io5";


const ModalityConsultation = () => {
  const { setPopup, popupState } = useContext(BasePopupContext);
  const user = useSelector(state => state.user);

  const openPopup = () => {
    // setting component details
    const popup = {
      component: <Container />,
      visible: true,
      heading: <span className=''>Consultation Mode</span>,
      size: "large",
      contained: true,
      hideDefaultClose: false,
    }
    // setting popup state
    setPopup(popup);
  }

  return (
    // <div className={`fixed top-0 w-64 !translate-x-1/2 sm:left-1/2 ${popupState.visible ? "z-10000" : "z-100000"} `}
    //   style={{
    //     translate: !user && "-50%"
    //   }}
    // >
    //   <div onClick={() => openPopup()} className={`bg-red-primary cursor-pointer text-center rounded-b-2xl text-sm text-white py-1  `}>
    //     <div className='flex-shrink-0 inline-flex items-center space-x-2 '>
    //       <IoEyeSharp className='text-xl  text-white' />
    //       <span className='  font-bold'>Display Mode Only</span>
    //       {/* <IoInformationCircleSharp className='text-sm  text-white' /> */}
    //     </div>

    //   </div>
    // </div>
    <div onClick={() => openPopup()} className={`bg-red-primary fixed top-0 w-full flex flex-col items-center cursor-pointer text-center  text-sm text-white py-2 ${popupState?.visible ? "z-10000" : "z-100000"}`}>
      <div className='flex-shrink-0 inline-flex space-x-2 mb-1 '>
        <IoEyeSharp className='text-xl  text-white' />
        <span className='  font-bold underline'>Display Mode Only</span>
      </div>

      {/* <p>
        Staring from Nov 22nd included (this Friday) the CSPRO should be put in modality consultation only. It’s mandatory that, from Nov 22nd, <span className='font-normal text-white'>SHALL NOT BE POSSIBLE TO INCLUDE ANY NEW ACTIVITY</span> in the system.
      </p> */}
      {/* <IoInformationCircleSharp className='text-sm  text-white' /> */}

    </div>
  )
}

export default ModalityConsultation



const Container = () => {

  return (
    <div className=''>
      <p className="text-base text-justify mb-3 text-red-primary font-bold">
        Dear User,
      </p>
      Starting from November 22nd, the CSPRO will be in consultation mode only. Users will not be able to create new activities or add anything new to the system. However, users will still be able to make changes to existing items.
      <p className='text-red-primary font-bold pt-3 my-2'>Sincerely,</p>
      <p>CSPRO teams</p>

    </div>
  )
}